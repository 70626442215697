body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.range-slider__range {
  background: #384151 !important;
  transition: height 0.3s !important;
}

.range-slider__thumb {
  background: #ffffff !important;
  transition: transform 0.3s !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #e7e7e7 !important;
}

.cl-impersonationFab {
  display: none;
}

.ruler {
  display: none;
}

.bar-left {
  background: #e7e7e7 !important;
  box-shadow: none !important;
}

.bar-right {
  background: #e7e7e7 !important;
  box-shadow: none !important;
}

.caption {
  display: none !important;
}

.thumb-right::before {
  background: white !important;
  height: 22px !important;
  width: 22px !important;
  margin-top: -7.5px !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  border-width: "1px" !important;
  border-color: #e7e7e7 !important;
  border-style: solid !important;
}

.thumb-left::before {
  background: white !important;
  height: 22px !important;
  width: 22px !important;
  margin-top: -7.5px !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  border-width: "1px" !important;
  border-color: #e7e7e7 !important;
  border-style: solid !important;
}

@font-face {
  font-family: "Inter";
  src: url("../public/Inter-Medium.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../public/Inter-SemiBold.ttf") format("truetype");
  font-weight: 500;
}

@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

@tailwind base;
@tailwind components;
@tailwind utilities;
